var countDownDate = new Date("Feb 2, 2021 00:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function () {
	// Get today's date and time
	var now = new Date().getTime();

	// Find the distance between now and the count down date
	var distance = countDownDate - now;

	// Time calculations for days, hours, minutes and seconds
	var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor(
		(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);

	// Display the result in the element with id="demo"
	document.getElementById("countdown").innerHTML =
		"<div class='wrap'>" + days + "<span>Days</span></div>" + "<div class='wrap'>" + hours + "<span>Hours</span></div>" + "<div class='wrap'>" + minutes + "<span>Minutes</span></div>"+ "<div class='wrap'>" + seconds + "<span>Seconds</span></div>";

	// If the count down is finished, write some text
	if (distance < 0) {
		clearInterval(x);
		document.getElementById("demo").innerHTML = "NOW AVAILABLE!";
	}
}, 1000);
