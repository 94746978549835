;
(function ($, _) {
    "use strict";

    // [landing_slideshow]

    $(document).ready(function () {

        // ah, what a storm of assumptions...
        // so, as far as I can tell, this form won't submit with duplicate named inputs,
        // but it was a massive pain in the ass to build just a single form imput set because of
        // the way the markup works

        // so welll just make sure that the #contact_fields_email_hero has a value
        $('.infusion-form').on('submit', function (ev) {
            if ("" == $('#contact_fields_email_hero').val()) {
                $('#contact_fields_email_hero').val($('#contact_fields_email_hero_mobile').val());
            }
            $('#contact_fields_email_hero_mobile').remove();
        });
    });

    //[tag_set]

    $(document).ready(function () {

        if (typeof window.mat_tag_sets === 'undefined') return false;

        var mat_tag_sets = window.mat_tag_sets;

        $.each(mat_tag_sets, function (index, tag_set_id) {
            setup_tag_set(tag_set_id);
        });
    });

    function setup_tag_set(set_id) {

        var $set = $(set_id),
            $letter_filter = $('.letter-filter', $set),
            $view_all = $('.view-all', $set),
            original_markup = $('.filter-item-set', $set).html();

        var letter_list = _.reduce($('.filter-item', $set), function (memo, el) {
            memo.push($(el).data('letter'));
            return memo;
        }, []);

        letter_list = _.uniq(letter_list);

        $.each(letter_list, function (i, letter) {
            $('.letter[data-letter="' + letter + '"]', $set).addClass('enabled').attr('aria-disabled', false);
        });

        $letter_filter.on('click', '.letter', function (letter_el) {
            letter_el.preventDefault();

            var letter = $(this).data('letter');

            if (!$(this).hasClass('enabled')) return false;

            $('.letter', $set).removeClass('selected');
            $(this).addClass('selected');

            $set.addClass('filtered');

            $('.filter-item-set', $set).html(original_markup);

            if ("featured" == letter) {
                $('.filter-item', $set).not('[data-featured="1"]').remove();
            } else {
                $('.filter-item', $set).not('[data-letter="' + letter + '"]').remove();
            }
            $view_all.show();
        });

        $view_all.hide();

        $view_all.on('click', function (button_el) {
            button_el.preventDefault();
            $('.filter-item-set', $set).html(original_markup);
            $('.letter', $set).removeClass('selected');
            $view_all.hide();
            $set.removeClass('filtered');
        });
        $('.letter.enabled', $letter_filter).not('.shortcode-tax-filter .letter.enabled').first().click();
        $('.letter[data-letter="featured"]', $letter_filter).click();

    }

    $(document).ready(function () {
        $('.return-to-top').on('click', function (ev) {
            ev.preventDefault();
            $("html, body").animate({scrollTop: 200}, "slow");
            return false;
        })
    });

    // helper functions


})(jQuery, _);