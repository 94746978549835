var stickyTop = jQuery(".62191f97453bf").offset().top;
var footer = jQuery('.footer').offset().top;

jQuery(window).on("scroll", function () {
	if (jQuery(window).scrollTop() >= stickyTop) {
		jQuery(".62191f97453bf").addClass("sticky")
	} else {
		jQuery(".62191f97453bf").removeClass("sticky")
	}
    if (jQuery(window).scrollTop() + 2000 >= footer) { 
        jQuery(".62191f97453bf").removeClass("sticky")
    }
});
