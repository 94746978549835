document.addEventListener('DOMContentLoaded', function() {
    // Find the source div containing the ratings
    var sourceDiv = document.querySelector('.recipe-ratings');

    // Find the target div where you want to copy the ratings
    var targetDiv = document.querySelector('.tasty-recipes-rating');

    // Check if both divs exist
    if (sourceDiv && targetDiv) {
        // Copy the contents of the source div into the target div
        targetDiv.innerHTML = sourceDiv.innerHTML;
    }
});
