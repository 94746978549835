;
(function ($, _) {
    "use strict";

    var CONTAINER_SELECTOR = '.post-grid-shortcode';
    var GRID_ITEM_SELECTOR = '.post-grid-item';

    var initial_content_html = "";

    $(document).ready(function () {

        var $grids = $(CONTAINER_SELECTOR);

        if ($grids.length < 1) return;

        _.each($grids, setupGrid);

    });

    function setupGrid($grid_container) {

        if ($($grid_container).hasClass('food-variant')) {
            return setupFoodGrid($grid_container);
        }
    }

    function setupDefault($grid_container) {
        var initial_content_html = $('.initial-content', $grid_container).html();
        $('.clear-all', $grid_container).on('click', clear_filters);
        $('.dietary-restrictions-filter', $grid_container).on('change', filter_recipes);
        $('.food-category-filter', $grid_container).on('change', filter_recipes);
        $('.sort-control-selector', $grid_container).on('change', sort_items);
    }

    function setupFoodGrid($grid_container) {
        initial_content_html = $('.initial-content', $grid_container).html();

        $('.clear-all', $grid_container).on('click', clear_filters);
        $('.parent-category-filter', $grid_container).on('change', filter_by_parent_category);
        $(CONTAINER_SELECTOR).addClass('shorten-post-list');
        if ($('.parent-category-filter', $grid_container).val())
            $('.parent-category-filter', $grid_container).trigger('change');
    }

    function setupRecipeGrid($grid_container) {
        initial_content_html = $('.initial-content', $grid_container).html();
        $('.clear-all', $grid_container).on('click', clear_filters);
        $('select', $grid_container).on('change', filter_recipes);
        $('select.sort-control-selector', $grid_container)
            .val('views')
            .change()
            .closest(CONTAINER_SELECTOR)
            .addClass('shorten-post-list')
            .find('.post-grid-items')
            .removeClass('hide');
    }

    function clear_filters(ev) {
        var $container = $(this).closest(CONTAINER_SELECTOR);
        $container.addClass('shorten-post-list');
        $('.initial-content', $container).html(initial_content_html);
        $("option[value='']", $container).prop('selected', true);
    }

    function filter_recipes(ev) {

        var $container = $(this).closest(CONTAINER_SELECTOR);
        $container.removeClass('shorten-post-list');
        var $init = $(initial_content_html);
        var $items = $(GRID_ITEM_SELECTOR, $init);

        var recipe_cats = $('.food-category-filter', $container).val(),
            diet = $('.dietary-restrictions-filter', $container).val(),
            ingredients = $('.ingredients-filter', $container).val(),
            sort_key = $('.sort-control-selector', $container).val();

        if ("" != diet)
            $items = _.filter($items, function (item) {
                var cats = $(item).data('restriction_cats');
                return (cats.indexOf(diet) >= 0)
            });

        if ("" != recipe_cats)
            $items = _.filter($items, function (item) {
                var cats = $(item).data('recipe_cats');
                return (cats.indexOf(recipe_cats) >= 0)
            });

        if ("" != ingredients) {
            $items = _.filter($items, function (item) {
                var cats = $(item).data('ingredient_cats');
                return (cats.indexOf(ingredients) >= 0)
            });
        }

        $items = _.filter($items, function (item) {
            return true;
        });

        $('.initial-content', $container).slideUp(500, function () {

            var new_content = "";
            if ($items.length > 0) {

                $items = $($items);
                switch (sort_key) {
                    case 'views':
                        var $wrapper = $("<div>");
                        $items.sort(function (a, b) {
                            var a_val = parseInt(a.getAttribute('data-views'));
                            var b_val = parseInt(b.getAttribute('data-views'));
                            if (a_val == b_val) return 0;
                            return (a_val > b_val) ? -1 : 1;
                            //return a < b;
                        }).each(function (index, item) {
                            new_content += $(item).get(0).outerHTML;
                        })
                        break;
                    case 'date':
                        var $wrapper = $("<div>");
                        $items.sort(function (a, b) {
                            var a_val = parseInt(a.getAttribute('data-date'));
                            var b_val = parseInt(b.getAttribute('data-date'));
                            if (a_val == b_val) return 0;
                            return (a_val > b_val) ? -1 : 1;
                            //return a < b;
                        }).each(function (index, item) {
                            new_content += $(item).get(0).outerHTML;
                        })
                        break;
                    case 'alpha':
                        $.each($items, function (index, item) {
                            new_content += $(item).get(0).outerHTML;
                        });
                        break;
                }
            } else {
                new_content = "<p class='empty'>Sorry: there are no matching items.</p>";
            }
            $('.initial-content', $container).html(new_content);
            $('.initial-content', $container).slideDown(500);
        });

    }

    function filter_by_parent_category(ev) {

        var $container = $(this).closest(CONTAINER_SELECTOR);
        $container.removeClass('shorten-post-list');
        var new_value = $(this).val();

        $('.initial-content', $container).html(initial_content_html);

        $(GRID_ITEM_SELECTOR, $container).each(function (index, item) {
            if ($(item).data('parent_cat') != new_value) $(this).hide(300);
        });
    }

})(jQuery, _);