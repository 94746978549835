// TOD: this is a super naive intepretation of what needs to happen.  It'ss 

;
(function ($) {
    "use strict";

    $(document).ready(function () {
        var $topics_widgets = $('.topics-widget');

        $topics_widgets.on('click', '.letter', function (el) {

            var $letter_element = $(this);
            if (!$letter_element.hasClass('exists')) return false;

            var letter_class = $letter_element.data('letter');

            $('.letter').removeClass('current');
            $letter_element.addClass('current');
            $('.topic').hide();

            $('.topic[data-letter="' + letter_class + '"]').show();

        });
    });

})(jQuery);