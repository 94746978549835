jQuery(document).ready(function () {

    getParameterByName = function (name) {
        query = window.location.search.toString();
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        results = regex.exec(query);
        if (results == null) return "";
        else return decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    var utmSource = getParameterByName('utm_source');

    if (utmSource == 0) {
        utmSource = "content";
    }

    var reExp = /utm_source=\\d+/;
    var url = jQuery("a.utm").attr('href');
    if (url) {
        var newUrl = url.replace(reExp, "utm_source=" + utmSource);
        jQuery("a.utm").attr("href", newUrl);
    }

});