;
(function ($, _) {
    "use strict";

    var current_page = 1;
    var $article_container = false;
    var tmpl_more_posts_ajax = false;

    $(document).ready(function () {
        $article_container = $('#article_container');

        if (
            $('.more-posts-ajax').length < 1 // no button
            || $('#more_posts_ajax_template').length < 1 // no template
            || $article_container.length < 1 // no continer
        ) {
            return;
        }

        tmpl_more_posts_ajax = _.template($('#more_posts_ajax_template').html());
        $('body').on('click', '.more-posts-ajax', load_more_posts);
    });

    function load_more_posts(ev) {

        ev.preventDefault();

        var $button = $(this),
            $loading_icon = $('.loading-icon'),
            url = $button.data('rest-url'),
            query = $button.data('rest-query'),
            max_pages = $button.data('rest-max-pages'),
            target_page = current_page + 1;

        var endpoint = url + "?" + query + "&page=" + target_page;
        $button.hide();
        $loading_icon.show();


        $.get(endpoint)
            .done(function (data) {
                current_page += 1;
                add_posts(data);
            })
            .fail(function () {
                console.log("error: could not load endpoint " + endpoint);
            })
            .always(function () {
                $loading_icon.hide();
                if (current_page < max_pages ) {
                    $button.show();
                }
            });
    }

    function add_posts(data) {
        _.each(data, function (el) {
            var markup = tmpl_more_posts_ajax({
                data: el
            });
            var $markup = $(markup);
            $article_container.append(
                "<article class='popular-post row new-content' style='display:none;'>"
                + $markup.html()
                + "</article>"
            );
        });
        $('.new-content').slideDown(600);
    }


})(jQuery, _);