;
(function ($, _, Cookies) {
    "use strict";
    
    var RATINGS_SELECTOR = '.mat-ratings';
    var RATINGS_CONTROL_GROUP_SELECTOR = '.mat-rating-control';
    var RATINGS_CONTROL_TOGGLE_SELECTOR = '.rating-toggle';
    var RATINGS_CONTROL_ICON_SELECTOR = '.rating-icon';
    var RATINGS_CONTROL_COUNT_SELECTOR = '.mat-rating-count';
    var RATINGS_COUNT_TEXT = '(Your Rating)'
    var RATINGS_DATA_ATTRIBUTE_VALUE = 'rating-value';
    var RATINGS_DATA_ATTRIBUTE_POST_ID = 'post-id';
    var RATINGS_RATE_ABLE_CLASS = 'rate-able';
    var RATINGS_LESS_THAN_CLASS = 'filled';

    var RATINGS_COOKIE_KEY = 'mat_ratings_collection';

    var ENDPOINT_URL = '/wp-json/mat-ratings/v1/vote';

    var user_saved_ratings = [];

    $(document).ready(function () {

        $(RATINGS_SELECTOR).on('click', RATINGS_CONTROL_TOGGLE_SELECTOR, handle_rating_click);

        // Get the cookie value
        var ratings_cookie_val = Cookies.get(RATINGS_COOKIE_KEY);

        if (!_.isEmpty(ratings_cookie_val)) {
            user_saved_ratings = JSON.parse(ratings_cookie_val);
            $.each(user_saved_ratings, function (index, rating) {
                var rater = $(RATINGS_SELECTOR + "[data-" + RATINGS_DATA_ATTRIBUTE_POST_ID + "='" + rating.post_id + "']");
                //set_ui_value(rater, rating.rating, rating.post_id);
                set_rater_lock(rater, rating.rating, rating.post_id);
                //set_rater_lock($this_parent, new_rating, post_id);
            });
        }
    });

    function handle_rating_click(ev) {
        ev.preventDefault();

        // make sure that we can do this:
        if (!$(RATINGS_CONTROL_GROUP_SELECTOR).first().hasClass(RATINGS_RATE_ABLE_CLASS)) return false;

        var $this = $(this);
        var $this_parent = $this.closest(RATINGS_SELECTOR);
        var new_rating = $this.data(RATINGS_DATA_ATTRIBUTE_VALUE);
        var post_id = $this_parent.data(RATINGS_DATA_ATTRIBUTE_POST_ID);

        set_ui_value($this_parent, new_rating, post_id);
        set_rater_lock($this_parent, new_rating, post_id);
        set_cookie_value($this_parent, new_rating, post_id);
        store_rating_in_wp(new_rating, post_id);
    }

    function set_rater_lock($this_parent, new_rating, post_id) {
        $(RATINGS_CONTROL_GROUP_SELECTOR, $this_parent).removeClass(RATINGS_RATE_ABLE_CLASS);
    }

    function set_ui_value($this_parent, new_rating, post_id) {
        $this_parent.children(RATINGS_CONTROL_COUNT_SELECTOR).text(RATINGS_COUNT_TEXT);
        $(RATINGS_CONTROL_ICON_SELECTOR, $this_parent).removeClass(RATINGS_LESS_THAN_CLASS);
        $(RATINGS_CONTROL_TOGGLE_SELECTOR + ":lt(" + new_rating + ")", $this_parent).children(RATINGS_CONTROL_ICON_SELECTOR).addClass(RATINGS_LESS_THAN_CLASS);
    }

    function set_cookie_value($this_parent, new_rating, post_id) {

        // cookie stuff:
        var new_rating_item = {
            post_id: post_id,
            rating: new_rating
        };

        var new_rating_list = [];

        new_rating_list = _.reject(user_saved_ratings, function (rating) {
            return rating.post_id == post_id;
        });

        new_rating_list.push(new_rating_item);

        Cookies.set(RATINGS_COOKIE_KEY, JSON.stringify(new_rating_list));
    }

    function store_rating_in_wp(new_rating, post_id) {
        $.get(ENDPOINT_URL, {post_id: post_id, value: new_rating});
    }

})(jQuery, _, Cookies);